






import { Component, Vue } from 'vue-property-decorator';
import PageContainer from '~/components/core/PageContainer.vue';

@Component({
	components: { PageContainer },
})
export default class Index extends Vue {}
